import React from 'react'
import NumBar from '../components/NumBar'
import { useNavigate } from "react-router-dom"

export default function RestoreWallet() {
    const navigate = useNavigate()
    return (
        <main className="w-full min-h-screen h-full flex flex-col items-center bg-[rgb(28,31,30)] text-white font-medium leading-6">
            <div className='w-[580px] mt-4 bg-[rgb(37,40,41)] rounded-lg p-4 flex flex-col gap-4 items-center'>
                <NumBar checkNum={1} />
                <h1 className='text-center font-bold text-xl leading-6'>
                    Restore an XDEFI Wallet
                </h1>

                <div className='w-full pt-4'>
                    <div className='w-full pb-12 grid grid-cols-2 gap-4'>
                        <button
                            className='h-[104px] w-full rounded-lg bg-[rgb(47,52,55)] flex flex-col gap-2 items-center justify-center hover:bg-[rgb(11,17,20)] active:scale-[0.98]'
                            onClick={() => navigate('/import')}
                        >
                            <img
                                src="/qoute.svg"
                                width={18}
                                height={18}
                                alt=""
                            />
                            <span className='font-medium text-base leading-6'>
                                Restore with secret phrase
                            </span>
                        </button>
                        <button
                            className='h-[104px] w-full rounded-lg bg-[rgb(47,52,55)] flex flex-col gap-2 items-center justify-center hover:bg-[rgb(11,17,20)] active:scale-[0.98]'
                            onClick={() => navigate('/legacy')}
                        >
                            <img
                                src="/save.svg"
                                width={18}
                                height={18}
                                alt=""
                            />
                            <span className='font-medium text-base leading-6'>
                                Restore legacy wallet
                            </span>
                        </button>
                    </div>

                    <div className='flex items-center justify-center'>
                        <button
                            className='w-[215px] h-12 rounded-lg bg-[rgb(47,52,55)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] font-medium text-base leading-5'
                            onClick={()=> navigate(-1)}
                        >
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </main>
    )
}
