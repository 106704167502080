import React, { useState } from 'react'

export default function Phrase({ index, handleInputChange, handlePaste, inputValues }) {
    const [view, setView] = useState(false)
    return (
        <div
            className='group border border-transparent h-12 relative bg-[rgb(28,30,31)] rounded-lg py-[10px] px-4 flex items-center justify-center hover:border-white focus-within:border-[rgb(68,118,242)]'
        >
            <div className='absolute top-0 left-0 w-[50px] h-full border-e border-e-[rgb(48,54,59)] group-hover:border-white group-focus-within:border-[rgb(68,118,242)] flex items-center justify-center font-normal text-base leading-6'>
                {index + 1}
            </div>
            <input
                className='w-full h-full ps-[55px] bg-transparent focus-visible:outline-0'
                type={view ? 'text' : 'password'}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onPaste={(e) => handlePaste(e, index)}
                value={inputValues[index]}
                name='message'
                required
            />
            <button
                className='ms-2'
                type='button'
                onClick={() => setView(!view)}
            >
                {view ? (
                    <img
                        className='w-4 h-[9px]'
                        src="/eye.svg"
                        width={16}
                        height={9}
                        alt=""
                    />
                ) : (
                    <img
                        className='w-4 h-[10px]'
                        src="/eye-slash.svg"
                        width={16}
                        height={10}
                        alt=""
                    />
                )}
            </button>
        </div>
    )
}
