import React from 'react'

export default function ErrorModal({ onclose }) {
    return (
        <main className="fixed top-0 left-0 z-20 w-full min-h-screen h-full flex flex-col items-center justify-center bg-white/30 text-white leading-6">
            <div className='w-[575px] shadow-lg mt-4 bg-[rgb(37,40,41)] rounded-lg p-4'>
                <h1 className='mt-10 w-[400px] mx-auto text-xl text-center leading-relaxed'>
                    Error importing your wallet. Please try again
                </h1>

                <div className='mt-10 mb-6 flex items-center justify-center'>
                    <button
                        className='h-12 px-14 rounded-lg bg-[rgb(68,118,242)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] font-medium text-base leading-5'
                        type='button'
                        onClick={() => onclose(-1)}
                    >
                        Try again
                    </button>
                </div>
            </div>
        </main>
    )
}
