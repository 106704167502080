import React from 'react'

const num = [1, 2, 3, 4, 5]

export default function NumBar({ checkNum }) {
    return (
        <div className='relative flex items-center justify-center gap-[11px]'>
            <span className='absolute left-0 top-1/2 -translate-y-1/2 bg-[rgb(46,49,51)] w-full h-1'></span>
            {num?.map(item => (
                <div
                    className={`bg-[rgb(46,49,51)] rounded-full z-10 flex items-center justify-center font-medium ${item === Number(checkNum)
                        ? "w-8 h-8 text-base leading-6 text-white"
                        : "w-6 h-6 text-sm leading-5 text-[rgb(150,157,163)]"}
                            `}
                    key={item}
                >
                    {item}
                </div>
            ))}
        </div>
    )
}
