import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate()
  return (
    <main className="w-full min-h-screen h-full flex flex-col items-center bg-[rgb(28,31,30)] text-white font-medium leading-6">
      <div className="flex flex-col items-center justify-start w-[832px]">
        <div className="pt-16">
          <img
            src="/main.svg"
            width={216}
            height={101}
            alt=""
          />
        </div>

        <div className="mt-4 w-full rounded-lg bg-[rgb(37,40,41)] p-4 gap-4 flex flex-col items-center justify-center">
          <h1 className="mb-12 text-3xl font-normal leading-10">
            Let's get started
          </h1>

          <div className="w-full flex flex-col gap-4 justify-center items-center">
            <p className="font-normal text-base leading-6">
              Choose an option to connect to get started
            </p>

            <div className="w-full grid grid-cols-3 items-center gap-4">
              <button
                className="w-full h-[104px] rounded-lg bg-[rgb(47,52,55)] hover:bg-[rgb(11,17,20)] flex flex-col items-center justify-center gap-2"
                disabled
              >
                <img
                  src="/plus.svg"
                  width={24}
                  height={24}
                  alt=""
                />
                <span className="font-medium text-base leading-6">
                  Create XDEFI Wallet
                </span>
              </button>
              <button
                className="w-full h-[104px] rounded-lg bg-[rgb(47,52,55)] hover:bg-[rgb(11,17,20)] active:scale-[0.98] flex flex-col items-center justify-center gap-2"
                onClick={()=> navigate("/restore")}
              >
                <img
                  src="/download.svg"
                  width={24}
                  height={24}
                  alt=""
                />
                <span className="font-medium text-base leading-6">
                  Restore XDEFI Wallet
                </span>
              </button>
              <button
                className="w-full h-[104px] rounded-lg bg-[rgb(47,52,55)] hover:bg-[rgb(11,17,20)] flex flex-col items-center justify-center gap-2"
                disabled
              >
                <img
                  src="/connect.svg"
                  width={24}
                  height={24}
                  alt=""
                />
                <span className="font-medium text-base leading-6">
                  Connect Hardware Wallet
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
